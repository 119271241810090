import React from "react";
import { Layout } from "../../components/Layout";
import { ServicesList } from "../../components/Services";
import { Seo } from "../../components/Seo";
import { PageHeader, PageTitle } from "../../components/PageHeader";
import { PageHeroWrap } from "../../components/PageHero";

const Page: React.FC = () => {
  return (
    <Layout>
      <Seo
        title="Software Development Services"
        description="LeanyLabs delivers custom software solutions."
        maxImagePreview="large"
      />
      <PageHeroWrap>
        <PageHeader>
          <PageTitle>Our Services</PageTitle>
        </PageHeader>

        <ServicesList />
      </PageHeroWrap>
    </Layout>
  );
};

export default Page;
